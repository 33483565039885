/*
Theme Name: Callbox Connex
Theme URI: https://github.com/
Author: David Berner
Author URI: https://davidberner.co.uk/
Description: Simple WP theme for Callbox sister site
Version: 4.0.4
*/

/* Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    font-size: 100%;
    font: inherit;
    padding: 0;
    border: 0;
    margin: 0;
    vertical-align: baseline;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    font-family: "Quicksand", sans-serif;
    margin: 0;
    padding: 10rem 0 0;
}

a {
    color: #cc6d6d;
}

@media (min-width: 46.25em) {
    body {
        padding-top: 6rem;
    }
}

/* Helpers */

.h-hide {
    display: none;
}

.h-hide-visually {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.h-spacing {
    margin-bottom: 2rem !important;
}

.h-spacing-top {
    margin-top: 2rem !important;
}

/* Layout */

.l-container {
    margin: 0 auto;
    max-width: 75rem;
    padding: 0 1.25rem;
    width: 100%;
}

.l-space-between {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.l-space-between {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
}

@media (min-width: 46.25em) {
    .l-grid__item--4-col-from-lap {
        justify-content: space-between;
    }
}

/* Header */

.c-header {
    background-color: #151515;
    background: linear-gradient(#505050, #151515);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.5);
    left: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
}

/* Logo */

.c-logo {
    align-items: flex-start;
    color: #fff;
    display: flex;
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    text-decoration: none;
}

@media (min-width: 46.25em) {
    .c-logo {
        margin-bottom: 0;
    }
}

.c-logo__icon {
    color: #e0110d;
    display: block;
    margin-right: 0.25rem;
    height: 2rem;
    width: 2rem;
}

/* Search filter / selects */

.c-search-filter {
    display: flex;
    max-width: 30rem;
}

.c-custom-select {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    display: block;
    position: relative;
    transition: background-color 0.3s ease;
    width: 100%;
}

.c-custom-select:hover {
    background-color: rgba(255, 255, 255, 1);
}

.c-custom-select--first {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.c-custom-select__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    display: block;
    font-size: 1rem; /* Avoid weird zoom on Apple devices */
    height: 2.5rem;
    line-height: 1;
    padding-left: 4.5rem;
    width: 100%;
}

@media (min-width: 50em) {
    .c-custom-select__select {
        font-size: 0.75rem;
    }
}
.c-custom-select__select::-ms-expand {
    display: none;
}

.c-custom-select__select:focus {
    box-shadow: 0 0 0 2px #0773a7;
    outline: none;
}

.c-custom-select--first .c-custom-select__select {
    border-radius: 0.25rem 0 0 0.25rem;
    padding-left: 3rem;
}

.c-custom-select__label {
    font-size: 0.8rem;
    font-weight: bold;
    left: 0.75rem;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.c-custom-select__button {
    background-color: #cc6d6d;
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0 0.25rem 0.25rem 0;
    color: white;
    cursor: pointer;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 0.75rem;
    transition: background-color 0.3s ease;
}

.c-custom-select__button:focus {
    box-shadow: 0 0 0 2px #007232;
    outline: none;
}

.c-custom-select__button:hover {
    background-color: #2abf6b;
}

.c-custom-select__search-icon {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
}

.c-location-icon {
    height: 1.5rem;
    margin-right: 0.25rem;
    width: 1.25rem;
    color: rgba(0, 0, 0, 0.6);
}

.l-grid {
    display: flex;
    flex-flow: row wrap;
    margin-left: -1.5rem;
    margin-bottom: -1.5rem;
}

.l-grid__item {
    display: flex;
    flex-basis: auto;
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
}

.l-grid__item--12-col {
    width: 100%;
}

@media (min-width: 35em) {
    .l-grid__item--6-col-from-palm {
        width: 50% !important;
    }
}

@media (min-width: 60em) {
    .l-grid__item--4-col-from-lap {
        width: 33.3333% !important;
    }
}

@media (min-width: 64em) {
    .l-grid__item--3-col-from-desk {
        width: 25% !important;
    }
}

.c-card {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    min-height: 20rem; /* 320px */
    position: relative;
    width: 100%;
}

.c-card__header {
    background-color: #47a0cc;
    background-image: url("./images/cubes.png"); /* fallback */
    background-image: url(./images/cubes.png),
        linear-gradient(90deg, #47a0cc 2.2%, #4babd1);
    border-radius: 0.5rem 0.5rem 0 0;
    color: white;
    min-height: 6rem;
    padding: 1.5rem 1rem 1rem;
    position: relative;
}

.c-card__avatar-image {
    border-radius: 3px;
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.c-card__avatar {
    background-color: white;
    border: 2px solid #47a0cc;
    border-radius: 5px;
    bottom: 0;
    height: 4rem;
    overflow: hidden;
    padding: 2px;
    position: absolute;
    right: 1rem;
    transform: translateY(25%);
    width: 4rem;
}

.c-card__footer {
    margin-top: auto;
}

/* Hack - shouldn't really style based on grid order */
.l-grid__item:nth-child(even) .c-card__header {
    background-color: #87b0b5;
    background-image: url(./images/cubes.png),
        linear-gradient(90deg, #87b0b5 2.2%, #a0d5d6);
}

.l-grid__item:nth-child(even) .c-card__avatar {
    border-color: #a0d5d6;
}

.c-card__title {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.25rem;
    font-weight: 300;
}

.c-card__body {
    padding: 1rem;
}

.c-card__meta-location {
    align-items: flex-start;
    display: flex;
    line-height: 1.2;
    margin-top: 2rem;
}

.l-flex-space-evenly {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2rem;
}

.c-button-icon {
    background-color: #cc6d6d;
	border-radius: 50%;
    display: inline-block;
    height: 2.5rem;
    position: relative;
    transition: background-color .3s ease;
    width: 2.5rem;
}

.c-button-icon__text {
  background-color: #505050;
  border-radius: 3px;
  color: white;
  display: block;
  font-size: 0.75rem;
  left: 100%;
  line-height: 1;
  margin: 0 0.5rem;
  opacity: 0;
  padding: 0.25rem 0.5rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 4s, opacity $g-transition-duration-slow ease-out;
  white-space: nowrap;
  z-index: 2;
}

.c-button-icon:focus .c-button-icon__text,
.c-button-icon:hover .c-button-icon__text {
    opacity: 1;
    transform: translate(0, -50%);
    transition: transform 0.3s,
        opacity 0.3s ease-in;
    transition-delay: 0.5s;
}

.c-button-icon__icon {
    color: #fff;
    height: 1.75rem;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1.75rem;
}

.c-card__meta-roles {
    color: #555;
    line-height: 1.5;
}

/* Spinner credit:
 https://projects.lukehaas.me/css-loaders/
*/
.is-loading .c-loader {
    font-size: 0.625rem;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(
        left,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
        left,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
        left,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
        left,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
        to right,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    position: absolute;
    top: 10rem;
    left: 50%;
    margin-left: -5.5em;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 3;
}

.is-loading .c-loader:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
}

.is-loading .c-loader:after {
    background-color: #ccc;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.is-loading::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #ccc;
    top: 0;
    left: 0;
    z-index: 1;
}

.c-footer {
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 2rem;
	text-align: center;
}


.c-panel {
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 0.25rem;
    margin: 0 auto;
    max-width: 48rem;
    padding: 2rem;
}

.c-panel--left {
    margin-left: 0;
}

.c-panel__heading {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.c-panel p {
    line-height: 1.6;
    font-size: 1.1rem;
}

.c-panel p + p {
    margin-top: 2.5rem;
}

.c-panel b {
    font-weight: 600;
}

/* Temporary hack */
#response:not(:empty) + .c-panel,
#response:empty {
    display: none;
}
